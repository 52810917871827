<template>
  <div class="popup">
    <div class="popup__body">
      <div class="popup__wrap">
        <button class="popup__close" aria-label="close popup">close</button>
        <div class="popup__content" @click.stop>
          <div class="popup__header">
            <h2 class="popup__title">{{ t("order_goods") }}</h2>

            <p class="popup__subtitle">
              {{ t("to_place_your_order_please_fill_out_the_form_feedback") }}
            </p>
          </div>
          <div class="popup__main">
            <form class="popup__form">
              <fieldset
                class="popup__form--label"
                v-bind:class="{ error: v$.name.$error }"
              >
                <input
                  type="text"
                  name="name"
                  autocomplete="off"
                  v-model="state.name"
                  id="form-name"
                  required
                />
                <label for="form-name">{{
                  t("your_name_and_patronymic")
                }}</label>
              </fieldset>

              <fieldset
                class="popup__form--label"
                v-bind:class="{ error: v$.email.$error }"
              >
                <input
                  type="email"
                  name="email"
                  autocomplete="off"
                  v-model="state.email"
                  id="form-email"
                  required
                />
                <label for="form-email">{{ t("your_contact_email") }}</label>
              </fieldset>

              <fieldset
                class="popup__form--label"
                v-bind:class="{ error: v$.tel.$error }"
              >
                <vue-tel-input
                  ref="myinput"
                  v-mask="mask"
                  v-model="state.tel"
                  :inputOptions="{
                    id: 'form-tel-cart',
                    type: 'tel',
                    name: 'tel',
                    required: 'required',
                    autocomplete: 'off',
                  }"
                  @close="
                    state.tel = '+ ' + pref;
                    this.$refs.myinput.focus();
                  "
                  mode="international"
                  class="form-questions__input"
                  @country-changed="Test"
                ></vue-tel-input>
                <label for="form-tel-cart">{{ t("phone_number") }}</label>
              </fieldset>

              <div class="popup__form--product product-form">
                <div class="product-form__top">
                  <p>{{ t("your_order") }}:</p>
                  <a
                    href="#"
                    aria-label="open-drop"
                    :class="{ active: showDrop }"
                    @click="dropToggle"
                  >
                    <span>{{ t("buy_extra") }}</span>
                  </a>

                  <transition name="drop">
                    <div class="product-form__drop" v-if="showDrop">
                      <a
                        href="#"
                        aria-label="close-drop"
                        @click="showDrop = false"
                        >close</a
                      >

                      <div class="product-form__drop--wrap">
                        <fieldset
                          class="product-form__drop--wrap--label"
                          v-for="product in productlist"
                          :key="product.id"
                        >
                          <input
                            type="checkbox"
                            :name="'product-' + product.id"
                            :value="product"
                            :id="'product-' + product.id"
                            v-model="checkedProducts"
                          />
                          <label :for="'product-' + product.id">
                            <span></span> {{ product.name }}</label
                          >
                        </fieldset>

                        <div class="product-form__drop--wrap--btn">
                          <button
                            class="product-form__drop--wrap--btn"
                            aria-label="Обрати"
                            @click="addMoreToCart(this.checkedProducts)"
                          >
                            {{ t("select") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
                <div class="product-form__bottom form-bottom" v-if="products">
                  <div
                    class="form-bottom__item"
                    v-for="item in products"
                    :key="item.id"
                  >
                    <div class="form-bottom__img">
                      <img
                        :src="url + item.image"
                        width="120"
                        height="120"
                        alt="asd"
                      />
                    </div>
                    <div class="form-bottom__descr">
                      <div
                        class="form-bottom__text"
                        v-html="item.description"
                      ></div>

                      <div class="form-bottom__model">
                        <input
                          type="checkbox"
                          :name="'product-' + item.id"
                          :id="'title-prod-' + item.id"
                          @click="dropProduct(item.id)"
                          checked
                        />
                        <label :for="'title-prod-' + item.id"
                          ><span></span>{{ item.name }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <fieldset class="popup__form--label with-textarea">
                <textarea
                  v-model="textarea"
                  type="text"
                  name="textarea"
                  id="form-text"
                >
                </textarea>
                <label for="form-text">{{ t("your_comment") }}</label>
              </fieldset>
              <button
                v-if="alertStatus === false"
                @click="submitForm"
                type="submit"
                class="popup__form--btn"
                aria-label="Відправити"
              >
                {{ t("push") }}
              </button>
              <div class="form-questions__alert" v-if="alertStatus === true">
                <p class="alert-style">
                  {{ t("thank_you_for_your_request_wait_for_a_call_soon") }}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import phoneCodes from "@/assets/phone-codes.json";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { required, email } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import axios from "axios";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const state = reactive({
      name: "",
      email: "",
      tel: "",
      textarea: "",
    });

    const rules = computed(() => {
      return {
        name: { required },
        email: { required, email },
        tel: { required },
      };
    });

    const v$ = useValidate(rules, state);

    return {
      t,
      state,
      v$,
    };
  },
  components: {
    VueTelInput,
  },
  data() {
    return {
      codes: phoneCodes,
      mask: "+ ## (###) ###-##-##",
      pref: null,
      alertStatus: false,
      products: this.$store.state.cart.cart,
      checkedProducts: [],
      valueName: false,
      valueEmail: false,
      valueTel: false,
      valueTextarea: false,
      "state.name": this.value,
      "state.email": this.value,
      "state.tel": this.value,
      textarea: this.value,
      showDrop: false,
    };
  },
  props: {
    productlist: {
      type: Array,
    },
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
    textareaShow: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    Test(ev) {
      let codes = [];
      codes = this.codes.filter((element) => {
        if (element.cc === ev.iso2) {
          return element;
        }
      });
      this.mask = codes[0].mask;
      this.pref = ev.dialCode;
    },
    dropProduct(id) {
      console.log("products_id: " + id);
      this.$store.commit("cart/dropCart", id);
    },
    addMoreToCart(products) {
      this.$store.commit("cart/addMoreToCart", products);
    },

    submitForm(e) {
      e.preventDefault();
      let self = this;
      //Если все поля верны отправляем запрос
      this.v$.$validate().then(function (promiseResult) {
        if (promiseResult) {
          self.postForm(
            self.state.name,
            self.state.email,
            self.state.tel,
            self.textarea
          );
        }
      });
    },
    async postForm(name, email, tel, textarea) {
      try {
        let productIds = "";
        this.$store.state.cart.cart.forEach((element) => {
          productIds += element.id + "|";
        });

        const config = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
          },
        };

        await axios.post(
          process.env.VUE_APP_API + "/api/uk/order",
          {
            name: name,
            email: email,
            tel: tel,
            comment: textarea,
            status_id: 1,
            products: productIds,
          },
          config
        );
      } catch (e) {
        alert("Ошибка запроса");
      } finally {
        //Вызов аллерта
        this.alertStatus = true;

        //Очистка инпутов + убираем красную подсветку
        this.state.name = "";
        this.state.email = "";
        this.state.tel = "";
        this.textarea = "";

        this.v$.name = true;
        this.v$.email = true;
        this.v$.tel = true;
      }
    },

    dropToggle: function (e) {
      e.preventDefault();
      this.showDrop = !this.showDrop;
    },
  },

  watch: {
    "state.name"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.length > 0) {
        this.valueName = true;
      } else {
        this.valueName = false;
      }
    },
    "state.email"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.length > 0) {
        this.valueEmail = true;
      } else {
        this.valueEmail = false;
      }
    },
    "state.tel"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.length > 0) {
        this.valueTel = true;
      } else {
        this.valueTel = false;
      }
    },
    textarea(currentValue) {
      this.$emit("textarea", currentValue);
      if (currentValue.length > 0) {
        this.valueTextarea = true;
      } else {
        this.valueTextarea = false;
      }
    },
  },

  mounted() {
    const vueTelInputCart = document.querySelectorAll(".popup .vue-tel-input");

    for (let index = 0; index < vueTelInputCart.length; index++) {
      const element = vueTelInputCart[index];
      const label = element.nextElementSibling;

      element.append(label);
    }
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.alert-style {
  color: green;
  width: 300px;
  margin: 10px;
  border: 1px solid green;
  padding: 10px;
  text-align: center;
}

.popup {
  position: fixed;
  top: 100px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  transition: all 0.3s linear;

  @media (max-width: 575.98px) {
    top: 0;
  }

  &.open {
    visibility: visible !important;
    opacity: 1;
  }

  &__body {
    position: relative;
    display: flex;
    margin-top: auto;
    flex: 1 1 auto;
    max-height: 100%;
    background: url("~@/assets/images/bg_call-back.jpg") no-repeat;
    background-size: cover;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#1c1c1c, 0.7);
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    margin: 0 auto;
    padding: 70px 15px;
    overflow-y: auto;

    @media (max-height: 699.98px) {
      overflow-y: auto;
    }
  }

  &__alert {
    margin-left: auto;
    margin-right: auto;
  }

  &__close {
    position: absolute;
    @include property("top", 45, 15, true, 768, 320);
    @include property("right", 300, 15, true, 1670, 1366);
    width: 41px;
    height: 41px;
    font-size: 0;
    background: url("~@/assets/images/close.svg") no-repeat;
    background-size: 50% 50%;
    background-position: center center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    text-align: center;
    @include property("font-size", 32, 24, true, 768, 320);
    line-height: calc(42 / 32);
    letter-spacing: 1.6px;
    color: #f9b80e;
    text-transform: uppercase;
  }

  &__subtitle {
    text-align: center;
    @include property("font-size", 32, 24);
    line-height: calc(42 / 32);
    letter-spacing: 1.6px;
    color: #e1e1e1;
    max-width: 730px;
    margin: 11px auto 0;
  }

  &__main {
    width: 100%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--label {
      position: relative;
      width: 100%;
      margin-top: 44px;

      &:not(.with-textarea) {
        max-width: 540px;
      }

      &.error {
        input {
          color: red;
          border-color: red;
        }

        label {
          color: red;
        }
      }

      input {
        font-size: 18px;
        line-height: calc(23 / 18);
        font-style: italic;
        letter-spacing: 0.9px;
        color: #e1e1e1;
        padding: 7px 10px;
        width: 100%;
        border-bottom: 0.5px solid #e1e1e1;

        &:focus,
        &:valid {
          ~ label {
            top: -5px;
          }
        }
      }

      label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        font-size: 18px;
        line-height: calc(23 / 18);
        font-style: italic;
        letter-spacing: 0.9px;
        color: #e1e1e1;
        transition: all 0.3s linear;
        cursor: text;
        width: 100%;
        text-align: center;
      }

      textarea {
        padding: 20px;
        background: #fff;
        width: 100%;
        min-height: 150px;
        color: #000;

        ~ label {
          transform: translate(0);
          width: auto;
          left: 20px;
          top: 20px;
          color: #000;
        }

        &:focus,
        &:valid {
          ~ label {
            top: -2px;
          }
        }
      }
    }

    &--btn {
      padding: 9px 32px 11px;
      border: 1px solid #e1e1e1;
      border-radius: 5px;
      margin-left: 20px;
      margin-top: 50px;
      transition: all 0.3s linear;
      color: #fff;

      @media (hover: hover) {
        &:hover {
          color: #f9b80e;
          border-color: #f9b80e;
        }
      }
    }
  }
}

.product-form {
  &__top {
    position: relative;
    display: grid;
    gap: 45px;
    @include property("gap", 45, 10, true, 1670, 576);
    @include property("margin-top", 45, 20);

    @media (min-width: 576px) {
      grid-template-columns: 1fr 1fr;
    }

    p,
    a {
      @include property("font-size", 24, 20);
      letter-spacing: 1.2px;
      color: #e1e1e1;
      margin-bottom: 0;
    }

    > a {
      position: relative;
      display: flex;
      align-items: center;

      span {
        border-bottom: 1px solid #fff;
        transition: all 0.3s linear;
      }

      &::after {
        content: "";
        display: block;
        width: 17px;
        height: 15px;
        flex: 0 0 auto;
        margin-left: 10px;
        background: #fff;
        mask-image: url("~@/assets/images/arrow-dropdown.svg");
        mask-repeat: no-repeat;
        mask-size: 100% 100%;
        transform: rotate(90deg);
        transition: all 0.3s linear;
      }

      &.active {
        text-decoration: none;
        color: #f9b80e;

        span {
          border-color: #f9b80e;
        }

        &::after {
          background: #f9b80e;
          transform: none;
        }
      }

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
          color: #f9b80e;

          span {
            border-color: #f9b80e;
          }

          &::after {
            background: #f9b80e;
          }
        }
      }
    }
  }

  &__drop {
    position: absolute;
    top: calc(100% + 5px);
    z-index: 9;
    width: 100%;
    max-width: 450px;
    padding: 50px 0 45px;
    @include property("padding-left", 100, 15, true, 768, 400);
    @include property("padding-right", 100, 15, true, 768, 400);
    background: url("~@/assets/images/bg-card.jpg") no-repeat;
    transition: all 0.3s linear;

    @media (min-width: 576px) {
      right: 0;
    }

    @media (max-width: 575.98px) {
      left: 0;
    }

    > a {
      position: absolute;
      top: 10px;
      right: 30px;
      width: 34px;
      height: 34px;
      font-size: 0;
      background: url("~@/assets/images/close.svg") no-repeat;
      background-size: 50% 50%;
      background-position: center center;
    }
    &--wrap {
      display: grid;
      gap: 16px;

      @media (min-width: 400px) {
        grid-template-columns: 1fr 1fr;
      }
      &--label {
        input {
          display: none;

          &:checked {
            + label {
              span {
                &::before {
                  opacity: 1;
                }
              }
            }
          }
        }
        label {
          display: flex;
          color: #fff;
          cursor: pointer;
          span {
            position: relative;
            display: block;
            width: 15px;
            height: 15px;
            border: 1px solid #e1e1e1;
            border-radius: 5px;
            margin-right: 9px;
            margin-top: 2.5px;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: url("~@/assets/images/check.svg") no-repeat;
              background-size: 100% 100%;
              opacity: 0;
              transition: all 0.3s linear;
            }
          }
        }
      }

      &--btn {
        @media (min-width: 400px) {
          grid-column: 2 span;
        }

        button {
          border: 1px solid #e1e1e1;
          border-radius: 5px;
          padding: 5px 15px;
          letter-spacing: 0.8px;
          color: #e1e1e1;
          font-size: 16px;
          transition: all 0.3s linear;

          @media (hover: hover) {
            &:hover {
              border-color: #f9b80e;
              color: #f9b80e;
            }
          }
        }
      }
    }
  }
}

.form-bottom {
  position: relative;
  display: grid;
  @include property("gap", 45, 20, true, 1024, 768);
  max-width: 800px;
  margin: 20px auto 0;
  color: #d6cfbe;

  @media (min-width: 650px) {
    grid-template-columns: 1fr 1fr;
  }

  &__item {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: 20px;
  }

  &__img {
    width: 120px;
    height: 120px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__model {
    position: relative;
    display: flex;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 20px;
    color: #f9b80e;

    input {
      display: none;
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    span {
      position: relative;
      border: 1px solid #d6cfbe;
      border-radius: 5px;
      margin-right: 7px;
      margin-top: -2px;
      flex: 0 0 auto;
      width: 17px;
      height: 17px;

      &::before {
        content: "";
        display: block;
        width: 18px;
        height: 14px;
        background: url("~@/assets/images/check.svg") no-repeat;
        background-size: 100% 100%;
        transition: all 0.3s linear;
      }
    }
  }
}

//// анимация появления
.drop-enter-from,
.drop-leave-to {
  opacity: 0;
}

.drop-enter-active .drop-container,
.drop-leave-active .drop-container {
  opacity: 1;
}
</style>
