<template>
  <div class="cart2">
    <h2 class="cart2__title">
      {{ getTranslatedName(data.translations, $i18n.locale) }}
    </h2>
    <div class="cart2__img">
      <v-lazy-image
        :src="url + data.image"
        rel="preload"
        width="361"
        height="309"
        :alt="data.alt"
      />
      <div class="cart2__descr">
        <div
          v-if="data"
          v-html="getTranslatedDescription(data.translations, $i18n.locale)"
        ></div>

        <button @click="modalToggle" :class="{ openPopup: showModal }">
          {{ t("add_to_cart") }}
        </button>
      </div>
    </div>
    <div class="cart2__price">
      {{ t("price") }}:
      <strong
        >{{ this.getPrice(data.price_0, data.price_1, data.price_2) }}
        {{ valuta }}</strong
      >
    </div>
    <transition name="popup">
      <Popup-cart
        :productlist="productlist"
        v-if="showModal"
        @click="showModal = false"
      />
    </transition>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PopupCart from "@/components/PopupCart.vue";
import VLazyImage from "v-lazy-image";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  components: {
    PopupCart,
    VLazyImage,
  },
  props: {
    productlist: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      employee: Object,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      showModal: false,
      showForm: false,
    };
  },
  methods: {
    getPrice(price_1, price_2, price_3) {
      if (price_1 !== undefined && price_1 !== null) {
        return price_1;
      }
      if (price_2 !== undefined && price_2 !== null) {
        if (this.$i18n.locale === "ru") {
          return "от " + price_2;
        } else {
          return "від " + price_2;
        }
      }
      if (price_3 !== undefined && price_3 !== null) {
        return price_3;
      }

      return false;
    },
    addToCart(product) {
      this.$store.commit("cart/addToCart", product);
    },
    modalToggle: function (e) {
      e.preventDefault();
      this.addToCart(this.data);
      this.showModal = !this.showModal;
    },
    getTranslatedName(transArray, locale) {
      let name = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          name = element.name;
        }
      });
      return name;
    },
    getTranslatedDescription(transArray, locale) {
      let description = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          description = element.description;
        }
      });
      return description;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

//// анимация появления
.popup-enter-from,
.popup-leave-to {
  opacity: 0;
}

.popup-enter-active .popup-container,
.popup-leave-active .popup-container {
  opacity: 1;
}

.cart2 {
  display: block;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;

      .cart2__descr {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 32, 24, true, 1024, 320);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    margin: 0 0 20px;
  }

  &__img {
    position: relative;
    padding-bottom: 86%;
    border: 1px solid #f9b80e;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 55%;
      height: 10px;
      background: #f9b80e;
      transform: translate(-50%, -100%);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__price {
    @include property("font-size", 24, 20, true, 1024, 320);
    line-height: calc(31 / 24);
    letter-spacing: 0.48px;
    color: #000000;
    text-align: center;
    margin-top: 10px;

    strong {
      display: inline-block;
      margin-left: 5px;
      font-weight: 700;
      @include property("font-size", 42, 32, true, 1024, 320);
      line-height: 1;
      letter-spacing: 0px;
    }

    span {
      display: inline-block;
      margin-left: 5px;
      font-size: 18px;
    }
  }

  &__descr {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% + 40px);
    height: calc(100% - 30px);
    color: #fff;
    @include property("padding-top", 35, 15, true, 1024, 320);
    @include property("padding-bottom", 35, 15, true, 1024, 320);
    @include property("padding-left", 30, 15, true, 1024, 320);
    @include property("padding-right", 30, 15, true, 1024, 320);
    transition: all 0.3s linear;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #1f2940 0%, #1c1c1c 100%);
      z-index: -1;

      @media (hover: none) {
        opacity: 0.7;
      }
    }

    @media (max-width: 575.98px) {
      width: calc(100% + 30px);
      height: calc(100% - 20px);
    }

    @media (hover: hover) {
      opacity: 0;
      visibility: hidden;
    }

    p {
      width: 100%;
      @include property("font-size", 18, 16, true, 1024, 320);
      line-height: calc(29 / 18);
      color: #e1e1e1;
      @include colLine(5);
    }

    button {
      color: #fff;
      background: #f9b80e;
      border-radius: 5px;
      padding: 10px 39px;
      transition: all 0.3s linear;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
          background: #ff8c09;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
